import React from 'react'
import SEO from './SEO'
import Header from './Header'
import '../scss/style.scss'

const Layout = props => (
  <>
    <SEO />
    <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper">
        <Header />
        {props.children}
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sub-footer-inner">
                <div className="copyright">
                  <span>Copyright © {new Date().getFullYear()}</span>
                  <a href="https://alfagis.bg" target="_blank" rel="noreferrer">
                    Алфагис ООД
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Layout
